import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    COUNTRY_REQUEST,
    CITY_REQUEST,
    COUNTRY_SUCCESS,
    CITY_SUCCESS,
    CTRY_ERROR
} from './define';

const state = {
    status: '',
    countries: [],
    cities: []
};

const getters = {
    getCountry: state => state.countries,
    getCity: state => state.cities
};

const actions = {
    [COUNTRY_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(COUNTRY_REQUEST);
            apiCall({ url: 'country/getcountry', data, method: 'POST' })
                .then(resp => {
                    commit(COUNTRY_SUCCESS, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(CTRY_ERROR);
                    reject(err);
                });
        });
    },
    [CITY_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(COUNTRY_REQUEST);
            apiCall({ url: 'country/getcity', data, method: 'POST' })
                .then(resp => {
                    commit(CITY_SUCCESS, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(CTRY_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [COUNTRY_REQUEST]: state => {
        state.status = 'loading';
    },
    [COUNTRY_SUCCESS]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'countries', resp.countries);
    },
    [CITY_SUCCESS]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'cities', resp.cities);
    },
    [CTRY_ERROR]: state => {
        state.status = 'error';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
