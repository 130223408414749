import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie } from '@/utils/cookie';

import auth from './modules/auth';
import user from './modules/user';
import doctor from './modules/doctor';
import patient from './modules/patient';
import clinic from './modules/clinic';
import branch from './modules/branch';
import availability from './modules/availability';
import ctrycity from './modules/ctrycity';
import setting from './modules/setting';
import notify from './modules/notify';
import socket from './modules/socket';
import calendar from './modules/calendar';
import article from './modules/article';
import recipe from './modules/recipe';
import report from './modules/report';
import page from './modules/page';
import bank from './modules/bank';
import appointment from './modules/appointment';
import email from './modules/email';
import video from './modules/video';
import payment from './modules/payment';
import comment from './modules/comment';
import question from './modules/question';
import symptom from './modules/symptom';
import disease from './modules/disease';
import treatment from './modules/treatment';
import extra from './modules/extra';
import paymentLink from './modules/paymentLink';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: '',
		userInfo: {
			email: '',
			lang: 'tr',
			name: '',
			phone: '',
			superadmin: false
		},
		userType: null,
		userLang: getCookie('user-lang'),
		menuToggle: false,
		loading: {common: false},
    },
    mutations: {
        changeMenuToggle: (state, status) => {
            state.menuToggle = status;
        },
        setLoading(state, obj){
			state.loading.common = obj.status
			var url = obj.url.match(/([a-z0-9]+)\/?([a-z]+)?\/?(\d+)?/)
			var module = url[1]
			var caption = url[2]
			var id = url[3]
			if(state.loading[module] == undefined){
				Vue.set(state.loading, module, {list: false});
			}
			if(caption){
				Vue.set(state.loading[module], caption, obj.status)
			}else{
				if(id){
					Vue.set(state.loading[module], 'id-'+id, obj.status)
				}else{
					state.loading[module].list = obj.status
				}
			}
		},
		resetLoading(state){
			for (const [module, subItems] of Object.entries(state.loading)) {
				for (const [caption, value] of Object.entries(subItems)) {
					state.loading[module][caption] = false
				}
			}
		},	
		setUserInfo(state, user){
			state.userInfo = user
		},
		setUserType(state, userType){
			state.userType = userType
		},
    },
    getters: {
		getLoading: (state) => (module, id = false) => {
			if(state.loading[module] == undefined){
				return false
			}
			if(id){
				if(!isNaN(parseFloat(id)) && isFinite(id)){
					if(state.loading[module]['id-'+id] == undefined){
						return false
					}
					return state.loading[module]['id-'+id]
				}else{
					if(state.loading[module][id] == undefined){
						return false
					}
					return state.loading[module][id]
				}
			}else{
				return state.loading[module]['list']
			}
		}
	},
    modules: {
        auth,
        user,
        doctor,
        patient,
        clinic,
        branch,
        availability,
        ctrycity,
        setting,
        notify,
        socket,
        calendar,
        article,
        recipe,
        report,
        page,
        bank,
        appointment,
        email,
        video,
        payment,
        comment,
        question,
		symptom,
		disease,
		treatment,
		extra,
        paymentLink
    },
    strict: false
});
