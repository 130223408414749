<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">
            <div class="toggleSection" @click="toggleSideBar">
                <i class="fas fa-bars"></i>
            </div>
            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">

            </navbar-toggle-button>
            <a class="navbar-brand" :href="clinic.webUrl!==undefined?`https://${clinic.webUrl}`:'https://e-doktor.com/'">
                <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="...">
                <img src="@/assets/img/logo_icon.png" class="navbar-brand-img-icon" alt="...">
            </a>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <base-dropdown class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0" menu-classes="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" v-if="$route.name!='notify'">
                        <a href="#" data-toggle="dropdown" class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                            <span class="mb-0 text-sm  font-weight-bold">
                                <i class="fas fa-bell"></i>
                                <b-badge variant="primary" class="badge-circle badge-md border-white" v-if="unread>0">{{unread}}</b-badge>
                            </span>
                        </a>
                        <template>
                            <div class="px-3 py-3 borderBottom" v-if="unread>0">
                                <h6 class="text-sm text-muted m-0" v-html="$options.filters.changeText($t('navbar.new_notify'), unread)"></h6>
                                <span class="readAll" @click.prevent="readAll">
                                    <small>{{$t('navbar.read_all')}}</small>
                                </span>
                            </div>
                            <div class="list-group list-group-flush">
                                <div :class="'list-group-item list-group-item-action'+(item.status?' bg-secondary':'')" v-for="(item, key) in notifies" :key="key">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h4 class="mb-0 text-sm">{{item.title}}</h4>
                                                </div>
                                                <div class="text-right text-muted">
                                                    <small>
                                                        <timeago :datetime="item.createdAt" :auto-update="60"></timeago>
                                                    </small>
                                                </div>
                                            </div>
                                            <p class="text-sm mb-0" v-html="getNotify(item.text)"></p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="notifies.length==0" class="text-center">
                                    <small>{{$t('info.not_found_notify')}}</small>
                                </div>
                            </div>
                            <router-link to="/notify" class="dropdown-item text-center text-primary font-weight-bold py-3 borderTop" v-if="notifies.length>0">{{$t('general.view_all')}}</router-link>
                        </template>
                    </base-dropdown>
                    <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                        <a href="#" class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                            <span class="mb-0 text-sm  font-weight-bold">{{profile.name}}</span>
                        </a>
                        <template>
                            <b-dropdown-item href="#!" to="/profile">
                                <i class="fas fa-user"></i>
                                <span>{{$t('general.profile')}}</span>
                            </b-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <b-dropdown-item v-on:click="logout">
                                <i class="fas fa-unlock-alt"></i>
                                <span>Logout</span>
                            </b-dropdown-item>
                        </template>
                    </base-dropdown>
                    <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a" v-if="setting.lang&&setting.lang.length>1">
                        <a href="#" class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                            <span class="mb-0 text-sm  font-weight-bold">{{userLang.toUpperCase()}}</span>
                        </a>
                        <template>
                            <b-dropdown-item href="#!" @click.prevent="changeLang(lang)" v-for="lang in setting.lang" :key="lang">
                                <span>{{lang.toUpperCase()}}</span>
                            </b-dropdown-item>
                        </template>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-10 collapse-brand">
                            <router-link to="/">
                                <img src="@/assets/img/logo.png">
                            </router-link>
                        </div>
                        <div class="col-2 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import NavbarToggleButton from '@/components/Arg/NavbarToggleButton';
import { mapState, mapGetters } from 'vuex';
import { getCookie, setCookie } from '@/utils/cookie';
import { AUTH_LOGOUT, NOTIFY_READALL, DOCTOR_UPDATE, PATIENT_UPDATE, USER_UPDATE } from '@/store/modules/define';
let self;

export default {
    name: 'sidebar',
    components: {
        NavbarToggleButton
    },
    computed: {
        ...mapGetters({ notifies: 'getNotifies', unread: 'getNotifyUnread', userType: 'getUserType', setting: 'generalSetting', profile: 'getProfile', clinic: 'getClinic' }),
        ...mapState(['userLang'])
    },
    data() {
        return {
            //logo : require('@/asset')
        };
    },
    props: {
        logo: {
            type: String,
            default: 'img/logo.png',
            description: ''
        },
        autoClose: {
            type: Boolean,
            default: true,
            description: ''
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    methods: {
        getNotify(text) {
            let arr = text.split('%s');
            let result = '';
            for (let i = 0; i < arr.length; i++) {
                if (i % 2 == 0) {
                    result += arr[i];
                } else {
                    result += `<strong class="text-primary">${arr[i]}</strong>`;
                }
            }

            if (result.length > 100) {
                result = result.substring(0, 100) + '...';
            }

            return result;
        },
        changeLang(lang) {
            let type = self.userType == 'admin' ? USER_UPDATE : self.userType == 'doctor' ? DOCTOR_UPDATE : PATIENT_UPDATE;
            let query = { uuid: self.profile.uuid };
            query[self.userType] = { lang: lang };

            self.$store.dispatch(type, query).then(resp => {
                setCookie('user-lang', lang);
                location.reload();
            });
        },
        toggleSideBar() {
            self.$store.commit('changeMenuToggle', !self.$store.state.menuToggle);
        },
        closeSidebar() {
            self.$sidebar.displaySidebar(false);
        },
        showSidebar() {
            self.$sidebar.displaySidebar(true);
        },
        logout() {
            const loginType = getCookie('user_login_type') || 'admin';
            const path = loginType == 'admin' ? '/login' : loginType == 'doctor' ? '/doctor-login' : '/patient-login';
            self.$store.dispatch(AUTH_LOGOUT).then(() => {
                self.$router.push({ path });
            });
        },
        readAll() {
            self.$store.dispatch('NOTIFY_READALL');
        }
    },
    filters: {
        changeText(text, number) {
            return text.replace('%d', `<strong class="text-primary">${number}</strong>`);
        }
    },
    beforeDestroy() {
        if (self.$sidebar.showSidebar) {
            self.$sidebar.showSidebar = false;
        }
    },
    created() {
        self = this;
    }
};
</script>
