import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    PAYMENT_LIST,
    PAYMENT_ERROR,
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_UPDATE,
    PAYMENT_TRANSFER,
    BALANCE_REQUEST
} from './define';

const state = {
    status: '',
    footer: {},
    payments: [],
    balances: [],
    total: 0,
    pages: 0
};

const getters = {
    getPayments: state => state.payments,
    getBalances: state => state.balances,
    getPaymentTotal: state => state.total,
    getPaymentPages: state => state.pages,
    getPaymentFooter: state => state.footer
};

const actions = {
    [PAYMENT_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(PAYMENT_REQUEST);
            apiCall({ url: 'payment/list', data, method: 'POST' })
                .then(resp => {
                    commit(PAYMENT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(PAYMENT_ERROR);
                    reject(err);
                });
        });
    },
    [BALANCE_REQUEST]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(BALANCE_REQUEST, []);
            apiCall({ url: 'balance/'+data.uuid})
                .then(resp => {
                    commit(BALANCE_REQUEST, resp);
                    resolve(true);
                })
                .catch(err => {
                    //console.log(err)
                    reject(err);
                });
        });
    },
    [PAYMENT_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PAYMENT_REQUEST);
            apiCall({
                url: 'payment/' + data.uuid,
                data: data.payment,
                method: 'PUT'
            })
                .then(resp => {
                    commit(PAYMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAYMENT_ERROR);
                    reject(err);
                });
        });
    },
    [PAYMENT_TRANSFER]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PAYMENT_REQUEST);
            apiCall({
                url: 'balance/add',
                data,
                method: 'POST'
            })
                .then(resp => {
                    commit(PAYMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAYMENT_ERROR);
                    reject(err);
                });
        });
    },
    ['ADD_PAYMENT']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'bank/payment/add',
                data,
                method: 'POST'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    ['DELETE_PAYMENT']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'payment/'+data.uuid,
                data,
                method: 'DELETE'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    }
};

const mutations = {
    [PAYMENT_REQUEST]: state => {
        state.status = 'loading';
    },
    [BALANCE_REQUEST]: (state, resp) => {
        Vue.set(state, 'balances', resp.balances);
    },
    [PAYMENT_SUCCESS]: state => {
        state.status = 'success';
    },
    [PAYMENT_ERROR]: state => {
        state.status = 'error';
    },
    [PAYMENT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'footer', resp.footer);
        Vue.set(state, 'payments', resp.payments);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
