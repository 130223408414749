import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    APPOINTMENT_REQUEST,
    APPOINTMENT_LIST,
    APPOINTMENT_UPDATE,
    APPOINTMENT_SUCCESS,
    APPOINTMENT_ERROR
} from './define';

const state = {
    status: '',
    appointments: [],
    total: 0,
    pages: 0
};

const getters = {
    getAppointments: state => state.appointments,
    getAppointmentTotal: state => state.total,
    getAppointmentPages: state => state.pages
};

const actions = {
    [APPOINTMENT_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(APPOINTMENT_REQUEST);
            apiCall({ url: 'appointment/list', data, method: 'POST' })
                .then(resp => {
                    commit(APPOINTMENT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(APPOINTMENT_ERROR);
                    reject(err);
                });
        });
    },
    [APPOINTMENT_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(APPOINTMENT_REQUEST);
            apiCall({
                url: 'appointment/' + data.id,
                data: data.appointment,
                method: 'PUT'
            })
                .then(resp => {
                    commit(APPOINTMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(APPOINTMENT_ERROR);
                    reject(err);
                });
        });
    },
    ['CREATE_APPOINTMENT']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'bank/payment',
                data,
                method: 'POST'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    ['UPDATE_APPOINTMENT']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'appointment',
                data,
                method: 'PUT'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    }
};

const mutations = {
    [APPOINTMENT_REQUEST]: state => {
        state.status = 'loading';
    },
    [APPOINTMENT_SUCCESS]: state => {
        state.status = 'success';
    },
    [APPOINTMENT_ERROR]: state => {
        state.status = 'error';
    },
    [APPOINTMENT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'appointments', resp.appointments);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    ['APPOINTMENT_CLEAR']: state => {
        state.status = '';
        state.appointments = [];
        state.total = 0;
        state.pages = 0;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
