import VueCookies from 'vue-cookies';

function getBaseDomain(hostname) {
    const regex = /(?:[\w-]+\.)?([\w-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)(?::\d+)?$/;
    const match = hostname.match(regex);
    return match ? match[1] : null;
}

let domain = getBaseDomain(window.location.hostname);
//console.log('domain', domain)
export const setCookie = (key, value, expire = '7d') => {
    return VueCookies.set(key, value, expire, '/', domain);
};

export const getCookie = key => {
    return VueCookies.get(key);
};

export const removeCookie = key => {
    return VueCookies.remove(key, '/', domain);
};