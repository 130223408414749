import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    RECIPE_ADD,
    RECIPE_DELETE,
    RECIPE_LIST,
    RECIPE_ERROR,
    RECIPE_REQUEST,
    RECIPE_SUCCESS,
    RECIPE_UPDATE
} from './define';

const state = {
    status: '',
    recipes: [],
    total: 0,
    pages: 0
};

const getters = {
    getRecipes: state => state.recipes,
    getRecipeTotal: state => state.total,
    getRecipePages: state => state.pages
};

const actions = {
    [RECIPE_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(RECIPE_REQUEST);
            apiCall({ url: 'recipe/list', data, method: 'POST' })
                .then(resp => {
                    commit(RECIPE_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(RECIPE_ERROR);
                    reject(err);
                });
        });
    },
    [RECIPE_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(RECIPE_REQUEST);
            apiCall({
                url: 'recipe/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(RECIPE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(RECIPE_ERROR);
                    reject(err);
                });
        });
    },
    [RECIPE_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(RECIPE_REQUEST);
            apiCall({
                url: 'recipe/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(RECIPE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(RECIPE_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [RECIPE_REQUEST]: state => {
        state.status = 'loading';
    },
    [RECIPE_SUCCESS]: state => {
        state.status = 'success';
    },
    [RECIPE_ERROR]: state => {
        state.status = 'error';
    },
    [RECIPE_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'recipes', resp.recipes);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
