import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    AVAILABILITY_REQUEST,
    AVAILABILITY_LIST,
    AVAILABILITY_ADD,
    AVAILABILITY_UPDATE,
    AVAILABILITY_SUCCESS,
    AVAILABILITY_ERROR
} from './define';

const state = {
    status: '',
    availability: []
};

const getters = {
    getAvailabilities: state => state.availability
};

const actions = {
    [AVAILABILITY_ADD]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(AVAILABILITY_REQUEST);
            apiCall({ url: 'availability', data, method: 'POST' })
                .then(resp => {
                    commit(AVAILABILITY_SUCCESS);
                    resolve(true);
                })
                .catch(err => {
                    commit(AVAILABILITY_ERROR);
                    reject(err);
                });
        });
    },
    [AVAILABILITY_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(AVAILABILITY_REQUEST);
            apiCall({ url: 'availability/'+data.doctor+'/'+data.start+'/'+data.end+'/'+data.type })
                .then(resp => {
                    commit(AVAILABILITY_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(AVAILABILITY_ERROR);
                    reject(err);
                });
        });
    },
    [AVAILABILITY_UPDATE]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(AVAILABILITY_REQUEST);
            apiCall({ url: 'availability', data, method: 'PUT' })
                .then(resp => {
                    commit(AVAILABILITY_SUCCESS);
                    resolve(true);
                })
                .catch(err => {
                    commit(AVAILABILITY_ERROR);
                    reject(err);
                });
        });
    },
};

const mutations = {
    [AVAILABILITY_REQUEST]: state => {
        state.status = 'loading';
    },
    [AVAILABILITY_SUCCESS]: state => {
        state.status = 'success';
    },
    [AVAILABILITY_ERROR]: state => {
        state.status = 'error';
    },
    [AVAILABILITY_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'availability', resp.availability);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
