import Vue from 'vue';
import apiCall from '@/utils/apiCall';

const state = {
    status: '',
    payments: [],
    total: 0,
    pages: 0,
    transaction: null
};

const getters = {
    getPaymentLinks: state => state.payments,
    getPaymentLinkTotal: state => state.total,
    getPaymentLinkPages: state => state.pages
};

const actions = {
    ['PAYMENT_LINK_REQUEST']: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit('PAYMENT_LINK_REQUEST');
            apiCall({ url: 'paymentlink/list', data, method: 'POST' })
                .then(resp => {
                    commit('PAYMENT_LINK_LIST', resp);
                    resolve(true);
                })
                .catch(err => {
                    commit('PAYMENT_LINK_ERROR');
                    reject(err);
                });
        });
    },
    ['PAYMENT_LINK_UPDATE']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit('PAYMENT_LINK_REQUEST');
            apiCall({
                url: 'paymentlink/' + data.uuid,
                data: data.payment,
                method: 'PUT'
            })
                .then(resp => {
                    commit('PAYMENT_LINK_SUCCESS');
                    resolve(resp);
                })
                .catch(err => {
                    commit('PAYMENT_LINK_ERROR');
                    reject(err);
                });
        });
    },
    ['ADD_PAYMENT_LINK']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'paymentlink/add',
                data,
                method: 'POST'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    ['DELETE_PAYMENT_LINK']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'paymentlink/'+data.uuid,
                data,
                method: 'DELETE'
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    async generateToken({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'transaction/generate',
                data,
                method: 'POST'
            })
            .then(resp => {
                if(resp.transaction){
                    commit('setTransaction', resp.transaction);
                    resolve({transaction: resp.transaction, paymentUrl: resp.paymentUrl});
                }else{
                    reject('unknown_error');
                }
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    async completeAppointment({ commit }, data) {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'bank/payment/add',
                data,
                method: 'POST'
            })
            .then(resp => {
                resolve(resp.status)
            })
            .catch(err => {
                reject(err);
            });
        });
    },
};

const mutations = {
    ['PAYMENT_LINK_REQUEST']: state => {
        state.status = 'loading';
    },
    ['PAYMENT_LINK_SUCCESS']: state => {
        state.status = 'success';
    },
    ['PAYMENT_LINK_ERROR']: state => {
        state.status = 'error';
    },
    ['PAYMENT_LINK_LIST']: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'payments', resp.payments);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    setTransaction(state, transaction) {
        state.transaction = transaction;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
