import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getCookie, setCookie, removeCookie } from '@/utils/cookie';

import DefaultLayout from '@/views/layout/Default.vue';
import AuthLayout from '@/views/layout/Auth.vue';

import NotFoundPage from '@/views/layout/NotFound.vue';

Vue.use(VueRouter);

/*const ifNotAuthenticated = (to, from, next) => {
    console.log('not', getCookie('user-token'))
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    console.log('auth', getCookie('user-token'))
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}*/

const routes = [
    {
        path: '/',
        redirect: 'appointment',
        component: DefaultLayout,
        children: [
            {
                path: '/appointment',
                name: 'appointment',
                icon: 'fas fa-calendar-check',
                component: () => import('../views/pages/Appointment/index.vue'),
                meta: { sidebar: true, requiresAuth: true }
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('../views/pages/Profile/index.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/wallet',
                name: 'wallet',
                component: () => import('../views/pages/Balance/index.vue'),
                meta: { requiresAuth: true, type: ['patient'] }
            },
            {
                path: '/clinic',
                name: 'clinic',
                icon: 'fas fa-clipboard-list',
                component: () => import('../views/pages/Clinic/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/branch',
                name: 'branch',
                icon: 'fas fa-building',
                component: () => import('../views/pages/Branch/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/doctor',
                name: 'doctor',
                icon: 'fas fa-user-md',
                component: () => import('../views/pages/Doctor/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin', 'clinic'] }
            },
            {
                path: '/patient',
                name: 'patient',
                icon: 'fas fa-procedures',
                component: () => import('../views/pages/Patient/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/patient-list',
                name: 'patientlist',
                icon: 'fas fa-procedures',
                component: () => import('../views/pages/Patient/Patient.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['doctor', 'clinic']
                }
            },
            {
                path: '/patient/files',
                name: 'files',
                icon: 'fas fa-file-alt',
                component: () => import('../views/pages/Patient/Files.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['patient'] }
            },
            {
                path: '/patient/recipe',
                name: 'recipe',
                icon: 'fas fa-receipt',
                component: () => import('../views/pages/Patient/Recipe.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['patient'] }
            },
            {
                path: '/patient/report',
                name: 'report',
                icon: 'fas fa-syringe',
                component: () => import('../views/pages/Patient/Report.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['patient'] }
            },
            {
                path: '/symptom',
                name: 'symptom',
                icon: 'fas fa-viruses',
                component: () => import('../views/pages/Symptom/Index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },

            {
                path: '/disease',
                name: 'disease',
                icon: 'fas fa-disease',
                component: () => import('../views/pages/Disease/Index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/treatment',
                name: 'treatment',
                icon: 'fas fa-stethoscope',
                component: () => import('../views/pages/Treatment/Index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/comment',
                name: 'comment',
                icon: 'fas fa-comments',
                component: () => import('../views/pages/Comment/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin', 'doctor'] }
            },
            {
                path: '/question',
                name: 'question',
                icon: 'fas fa-question-square',
                component: () => import('../views/pages/Question/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin', 'doctor'] }
            },
            {
                path: '/availability',
                name: 'availability',
                icon: 'fas fa-clipboard-list',
                component: () =>
                    import('../views/pages/Availability/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin', 'doctor', 'clinic']
                }
            },
            {
                path: '/calendar',
                name: 'calendar',
                icon: 'fas fa-calendar-alt',
                component: () => import('../views/pages/Calendar/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['doctor'] }
            },
            {
                path: '/article',
                name: 'article',
                icon: 'far fa-newspaper',
                component: () => import('../views/pages/Article/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin', 'doctor']
                }
            },
            {
                path: '/video',
                name: 'video',
                icon: 'fas fa-video',
                component: () => import('../views/pages/Video/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['doctor']
                }
            },
            {
                path: '/staticpage',
                name: 'staticpage',
                icon: 'fas fa-file-alt',
                component: () => import('../views/pages/Page/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/notify',
                name: 'notify',
                component: () => import('../views/staticpages/Notify.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/bank',
                name: 'bank',
                icon: 'fas fa-university',
                component: () => import('../views/pages/Bank/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/email',
                name: 'email',
                icon: 'fas fa-envelope-open',
                component: () => import('../views/pages/Email/index.vue'),
                meta: { sidebar: true, requiresAuth: true, type: ['admin'] }
            },
            {
                path: '/payment',
                name: 'payment',
                icon: 'far fa-credit-card',
                component: () => import('../views/pages/Payment/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin', 'doctor', 'clinic']
                }
            },
            {
                path: '/paymentlink',
                name: 'paymentLink',
                icon: 'far fa-receipt',
                component: () => import('../views/pages/PaymentLink/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin', 'patient', 'clinic']
                }
            },
            {
                path: '/user',
                name: 'user',
                icon: 'fas fa-users',
                component: () => import('../views/pages/User/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin'],
                    superadmin: true
                }
            },
            {
                path: '/setting',
                name: 'setting',
                icon: 'fas fa-cog',
                component: () => import('../views/pages/Setting/index.vue'),
                meta: {
                    sidebar: true,
                    requiresAuth: true,
                    type: ['admin'],
                    superadmin: true
                }
            }
        ]
    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/auth/Login.vue')
            },
            {
                path: '/doctor-login',
                name: 'doctorlogin',
                component: () => import('../views/auth/DoctorLogin.vue'),
                children: [
                    {
                        path: '/doctor-register',
                        name: 'doctorregister',
                        component: () => import('../views/auth/DoctorLogin.vue')
                    },
                    {
                        path: '/doctor-forget',
                        name: 'doctorforget',
                        component: () => import('../views/auth/DoctorLogin.vue')
                    }
                ]
            },
            {
                path: '/patient-login',
                name: 'patientlogin',
                component: () => import('../views/auth/PatientLogin.vue'),
                children: [
                    {
                        path: '/patient-register',
                        name: 'patientregister',
                        component: () =>
                            import('../views/auth/PatientLogin.vue')
                    },
                    {
                        path: '/patient-forget',
                        name: 'patientforget',
                        component: () =>
                            import('../views/auth/PatientLogin.vue')
                    }
                ]
            }
        ]
    },
    { path: '*', component: NotFoundPage, name: 'notFound' },
    {
        path: '/',
        component: () => import('../views/layout/Permission.vue'),
        name: 'permissionDenied'
    },
    {
        path: '/error',
        component: () => import('../views/layout/NotExists.vue'),
        name: 'notExists'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    let clinicQuery = {}
    let id = null
    if(!window.location.host.includes('-panel')){
        let host = window.location.host.split(':')[0];
        clinicQuery = {panelUrl:host, panelUrlStatus: true}
        id = 'privateAdmin';
        /*let host = window.location.host.split('.')
        window.location.href = window.location.href.split('://')[0]+'://admin-panel.'+(host.slice(1,host.length).join('.'))*/
    }else{
        id = window.location.host.split('-panel')[0];
        clinicQuery = {seo:id}
    }

    removeCookie('user-clinic');
    if(id && id!='admin' && to.name!='notExists'){
        await store.dispatch('CLINIC_EXISTS', clinicQuery).then(resp=>{
            if(resp.seo !== undefined){
                setCookie('user-clinic', resp.seo);
            }else{
                next({
                    name: 'notExists'
                });
            }
            //console.log(resp)
        }).catch(err=>{
            //console.log('err', err)
            next({
                name: 'notExists'
            });
        })
    }else{
        setCookie('user-clinic', 'e-doktor');
    }

    const token = getCookie('user-token');

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token == null || !store.getters.isAuthenticated) {
            const loginType = getCookie('user_login_type') || 'admin';
            const path =
                loginType == 'admin'
                    ? '/login'
                    : loginType == 'doctor'
                    ? '/doctor-login'
                    : '/patient-login';
            next({
                path,
                params: { nextUrl: to.fullPath }
            });
        } else {
            await store
                .dispatch('USER_TYPE')
                .then(type => {
                    if (to.meta.type != undefined) {
                        if (to.meta.type.indexOf(type) !== -1) {
                            next();
                        } else {
                            next({
                                name: 'permissionDenied'
                            });
                        }
                    }
                    next();
                })
                .catch(err => {
                    //console.log(err);
                    const loginType = getCookie('user_login_type') || 'admin';
                    const path =
                        loginType == 'admin'
                            ? '/login'
                            : loginType == 'doctor'
                            ? '/doctor-login'
                            : '/patient-login';
                    next({
                        name: 'permissionDenied'
                    });
                });
        }
    } else {
        if (
            [
                'patientforget',
                'doctorforget',
                'patientregister',
                'patientlogin',
                'login',
                'doctorlogin',
                'doctorregister'
            ].indexOf(to.name) !== -1 &&
            token !== null
        ) {
            window.location.href= '/appointment';
        } else {
            next();
        }
    }
});

export default router;
