import { getCookie, removeCookie } from '@/utils/cookie';
import axios from 'axios';
import https from 'https';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    }),
});

axiosInstance.interceptors.request.use(config => {
    const token = getCookie('user-token');
    const session = getCookie('user-session');
    if (token) {
        config.headers['Authorization'] = token;
    }
    if (session) {
        config.headers['Session'] = session;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async function(error) {
        if (error.response.status === 401) {
            removeCookie('user-token');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
