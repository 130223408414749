<template>
    <div class="main-content bg-default">
        <notifications></notifications>
        <div class="main-content">
            <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
                <router-view></router-view>
            </zoom-center-transition>
        </div>
    </div>
</template>
<script>
import { BaseNav } from '@/components/Arg'
import { ZoomCenterTransition } from 'vue2-transitions'

export default {
    components: {
        BaseNav,
        ZoomCenterTransition,
    },
    props: {
        backgroundColor: {
            type: String,
            default: 'black',
        },
    },
    data() {
        return {
            showMenu: false,
            pageTransitionDuration: 200,
            pageClass: 'login-page',
        }
    },
    computed: {
        title() {
            return `${this.$route.name} Page`
        },
    }
}
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
        opacity: 1;
    }
}

.main-content .zoomIn {
    animation-name: zoomIn8;
}

@keyframes zoomOut8 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-content .zoomOut {
    animation-name: zoomOut8;
}
</style>