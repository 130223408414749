import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    BRANCH_ADD,
    BRANCH_DELETE,
    BRANCH_LIST,
    BRANCH_LIST_REQUEST,
    BRANCH_ERROR,
    BRANCH_REQUEST,
    BRANCH_SUCCESS,
    BRANCH_UPDATE
} from './define';

const state = {
    status: '',
    branches: [],
    branchList: [],
    total: 0,
    pages: 0
};

const getters = {
    getBranches: state => state.branches,
    getBranchList: state => state.branchList,
    getBranchTotal: state => state.total,
    getBranchPages: state => state.pages
};

const actions = {
    [BRANCH_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(BRANCH_REQUEST);
            apiCall({ url: 'branch/getall', data, method: 'POST' })
                .then(resp => {
                    commit(BRANCH_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(BRANCH_ERROR);
                    reject(err);
                });
        });
    },
    [BRANCH_LIST_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(BRANCH_REQUEST);
            apiCall({
                url: 'branch/getall',
                data: { pagination: { limit: 1000 } },
                method: 'POST'
            })
                .then(resp => {
                    commit(BRANCH_LIST_REQUEST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(BRANCH_ERROR);
                    reject(err);
                });
        });
    },
    [BRANCH_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(BRANCH_REQUEST);
            apiCall({
                url: 'branch/' + data.uuid,
                data: data.branch,
                method: 'PUT'
            })
                .then(resp => {
                    commit(BRANCH_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BRANCH_ERROR);
                    reject(err);
                });
        });
    },
    [BRANCH_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(BRANCH_REQUEST);
            apiCall({
                url: 'branch/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(BRANCH_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BRANCH_ERROR);
                    reject(err);
                });
        });
    },
    [BRANCH_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(BRANCH_REQUEST);
            apiCall({
                url: 'branch/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(BRANCH_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BRANCH_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [BRANCH_REQUEST]: state => {
        state.status = 'loading';
    },
    [BRANCH_SUCCESS]: state => {
        state.status = 'success';
    },
    [BRANCH_ERROR]: state => {
        state.status = 'error';
    },
    [BRANCH_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'branches', resp.branches);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    [BRANCH_LIST_REQUEST]: async (state, resp) => {
        state.status = 'success';

        Vue.set(state, 'branchList', resp.branches);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
