import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    PAGE_ADD,
    PAGE_DELETE,
    PAGE_LIST,
    PAGE_ERROR,
    PAGE_REQUEST,
    PAGE_SUCCESS,
    PAGE_UPDATE
} from './define';

const state = {
    status: '',
    pageList: [],
    total: 0,
    pages: 0
};

const getters = {
    getPageList: state => state.pageList,
    getPageTotal: state => state.total,
    getPagePages: state => state.pages
};

const actions = {
    [PAGE_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(PAGE_REQUEST);
            apiCall({ url: 'page/list', data, method: 'POST' })
                .then(resp => {
                    commit(PAGE_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(PAGE_ERROR);
                    reject(err);
                });
        });
    },
    [PAGE_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PAGE_REQUEST);
            apiCall({
                url: 'page/' + data.id,
                data: data.page,
                method: 'PUT'
            })
                .then(resp => {
                    commit(PAGE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAGE_ERROR);
                    reject(err);
                });
        });
    },
    [PAGE_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PAGE_REQUEST);
            apiCall({
                url: 'page',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(PAGE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAGE_ERROR);
                    reject(err);
                });
        });
    },
    [PAGE_DELETE]: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            commit(PAGE_REQUEST);
            apiCall({
                url: 'page/' + id,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(PAGE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAGE_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [PAGE_REQUEST]: state => {
        state.status = 'loading';
    },
    [PAGE_SUCCESS]: state => {
        state.status = 'success';
    },
    [PAGE_ERROR]: state => {
        state.status = 'error';
    },
    [PAGE_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'pageList', resp.pageList);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
