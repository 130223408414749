import Vue from 'vue';
import apiCall from '@/utils/apiCall';

const state = {
    status: '',
    questions: [],
    total: 0,
    pages: 0
};

const getters = {
    getQuestions: state => state.questions,
    getQuestionTotal: state => state.total,
    getQuestionPages: state => state.pages
};

const actions = {
    ['QUESTION_LIST_REQUEST']: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit('QUESTION_REQUEST');
            apiCall({ url: 'doctor/question/list', data, method: 'POST' })
                .then(resp => {
                    commit('QUESTION_LIST', resp);
                    resolve(true);
                })
                .catch(err => {
                    commit('QUESTION_ERROR');
                    reject(err);
                });
        });
    },
    ['QUESTION_UPDATE']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit('QUESTION_REQUEST');
            apiCall({
                url: 'doctor/question/' + data.uuid,
                data: data.question,
                method: 'PUT'
            })
                .then(resp => {
                    commit('QUESTION_SUCCESS');
                    resolve(resp);
                })
                .catch(err => {
                    commit('QUESTION_ERROR');
                    reject(err);
                });
        });
    },
    ['QUESTION_SEND_ANSWER']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit('QUESTION_REQUEST');
            apiCall({
                url: 'doctor/question/answer/' + data.uuid,
                data: data.question,
                method: 'POST'
            })
                .then(resp => {
                    commit('QUESTION_SUCCESS');
                    resolve(resp);
                })
                .catch(err => {
                    commit('QUESTION_ERROR');
                    reject(err);
                });
        });
    },
    ['QUESTION_DELETE']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit('QUESTION_REQUEST');
            apiCall({
                url: 'doctor/question/' + data.uuid,
                data,
                method: 'DELETE'
            })
                .then(resp => {
                    commit('QUESTION_SUCCESS');
                    resolve(resp);
                })
                .catch(err => {
                    commit('QUESTION_ERROR');
                    reject(err);
                });
        });
    }
};

const mutations = {
    ['QUESTION_REQUEST']: state => {
        state.status = 'loading';
    },
    ['QUESTION_SUCCESS']: state => {
        state.status = 'success';
    },
    ['QUESTION_ERROR']: state => {
        state.status = 'error';
    },
    ['QUESTION_LIST']: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'questions', resp.questions);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
