<template>
    <base-nav container-classes="container-fluid" class="navbar-top navbar-expand" :class="{'navbar-dark': type === 'default'}">
        <a aria-current="page" class="h4 mb-0 text-white text-uppercase d-lg-inline-block active router-link-active"> {{$t('pages.'+$route.name)}} </a>
        <b-navbar-nav class="align-items-center ml-md-auto">
            <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0" menu-classes="dropdown-menu-xl" v-if="$route.name!='notify'">
                <a class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                    <span class="mb-0 text-sm  font-weight-bold">
                        <i class="fas fa-bell"></i>
                        <b-badge variant="primary" class="badge-circle badge-md border-white" v-if="unread>0">{{unread}}</b-badge>
                    </span>
                </a>
                <template>
                    <div class="px-3 py-3 borderBottom" v-if="unread>0">
                        <h6 class="text-sm text-muted m-0" v-html="$options.filters.changeText($t('navbar.new_notify'), unread)"></h6>
                        <span class="readAll" @click.prevent="readAll">
                            <small>{{$t('navbar.read_all')}}</small>
                        </span>
                    </div>
                    <div class="list-group list-group-flush">
                        <div :class="'list-group-item list-group-item-action'+(item.status?' bg-secondary':'')" v-for="(item, key) in notifies" :key="key">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 class="mb-0 text-sm">{{item.title}}</h4>
                                        </div>
                                        <div class="text-right text-muted">
                                            <small>
                                                <timeago :datetime="item.createdAt" :auto-update="60" :locale="userLang"></timeago>
                                            </small>
                                        </div>
                                    </div>
                                    <p class="text-sm mb-0" v-html="getNotify(item.text)"></p>
                                </div>
                            </div>
                        </div>
                        <div v-if="notifies.length==0" class="text-center">
                            <small>{{$t('info.not_found_notify')}}</small>
                        </div>
                    </div>
                    <router-link to="/notify" class="dropdown-item text-center text-primary font-weight-bold py-3 borderTop" v-if="notifies.length>0">{{$t('general.view_all')}}</router-link>
                </template>
            </base-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="align-items-center ml-auto ml-md-0">
            <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
                <a href="#" class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                    <span class="mb-0 text-sm  font-weight-bold">{{profile.name}}</span>
                </a>
                <template>
                    <b-dropdown-item href="#!" to="/profile">
                        <i class="fas fa-user"></i>
                        <span>{{$t('general.profile')}}</span>
                    </b-dropdown-item>
                    <div class="dropdown-divider"></div>
                    <template v-if="userType=='patient'">
                        <b-dropdown-item href="#!" to="/wallet">
                            <i class="fas fa-wallet"></i>
                            <span>{{$t('general.my_wallet')}}</span>
                        </b-dropdown-item>
                        <div class="dropdown-divider"></div>
                    </template>
                    <b-dropdown-item v-on:click="logout">
                        <i class="fas fa-unlock-alt"></i>
                        <span>{{$t('general.logout')}}</span>
                    </b-dropdown-item>
                </template>
            </base-dropdown>
            <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a" v-if="setting.lang&&setting.lang.length>1">
                <a href="#" class="btn-rotate dropdown-toggle nav-link" @click.prevent slot="title-container">
                    <span class="mb-0 text-sm  font-weight-bold">{{userLang.toUpperCase()}}</span>
                </a>
                <template>
                    <b-dropdown-item href="#!" @click.prevent="changeLang(lang)" v-for="lang in setting.lang" :key="lang">
                        <span>{{lang.toUpperCase()}}</span>
                    </b-dropdown-item>
                </template>
            </base-dropdown>
        </b-navbar-nav>
    </base-nav>
</template>
<script>
import moment from 'moment';
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components/Arg';
import { mapState, mapGetters } from 'vuex';
import { AUTH_LOGOUT, NOTIFY_READALL, DOCTOR_UPDATE, PATIENT_UPDATE, USER_UPDATE } from '@/store/modules/define';
import { getCookie, setCookie } from '@/utils/cookie';
let self;

export default {
    components: {
        CollapseTransition,
        BaseNav,
        Modal
    },
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: ''
        }
    },
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({ notifies: 'getNotifies', unread: 'getNotifyUnread', userType: 'getUserType', profile: 'getProfile', setting: 'generalSetting' }),
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        }
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: ''
        };
    },
    methods: {
        getNotify(text) {
            let arr = text.split('%s');
            let result = '';
            for (let i = 0; i < arr.length; i++) {
                if (i % 2 == 0) {
                    result += arr[i];
                } else {
                    result += `<strong class="text-primary">${arr[i]}</strong>`;
                }
            }

            if (result.length > 100) {
                result = result.substring(0, 100) + '...';
            }

            return result;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        logout() {
            const loginType = getCookie('user_login_type') || 'admin';
            const path = loginType == 'admin' ? '/login' : loginType == 'doctor' ? '/doctor-login' : '/patient-login';
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push({ path });
            });
        },
        readAll() {
            self.$store.dispatch('NOTIFY_READALL');
        },
        changeLang(lang) {
            let type = self.userType == 'admin' ? USER_UPDATE : self.userType == 'doctor' ? DOCTOR_UPDATE : PATIENT_UPDATE;
            let query = { uuid: self.profile.uuid };
            query[self.userType] = { lang: lang };

            self.$store.dispatch(type, query).then(resp => {
                setCookie('user-lang', lang);
                location.reload();
            });
        }
    },
    filters: {
        changeText(text, number) {
            return text.replace('%d', `<strong class="text-primary">${number}</strong>`);
        }
    },
    created() {
        self = this;
    }
};
</script>