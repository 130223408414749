<template>
	<div>
		<b-input-group>
			<template #prepend>
				<el-select v-model="langItem" style="width:100px" :placeholder="$t('general.select')">
					<el-option v-for="(lang, index) in compLanguages" :key="index" :value="lang"></el-option>
				</el-select>
			</template>
			<b-form-input type="text" autocomplete="off" v-model="languageValue"></b-form-input>
		</b-input-group>
		<div v-if="okbtn" style="margin-top:5px">
			<base-button type="primary" size="sm" @click="change">{{$t('general.btnUpdate')}}</base-button>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
export default {
	name: 'LanguageInput',
	props: {
		value: {
			type: Array
		},
		okbtn: {
			type: Boolean,
			default: false
		}
	},
    data() {
        return {
            activeLanguages: ['tr', 'en', 'de', 'ru'],
			lang: 'tr'
		}
    },
    computed: {
		langItem: {
			get(){
				return this.lang
			},
			set(val){
				this.lang = val
			}
		},		
		compLanguages(){
			return _.sortBy(this.activeLanguages, lang => (_.find(this.value, {lang}) ? _.find(this.value, {lang}).value : '') == '' ? 1 : 0)
		},
        languageValue: {
            get() {
				var lang = this.lang
				if(_.find(this.value, {lang})){
					return _.find(this.value, {lang}).value
				}
				return ''
            },
            set(val) {
				var lang = this.lang
				if(val.length == 0){
					if(_.find(this.value, {lang})){
						this.value.splice(_.findIndex(this.value, {lang}), 1)
					}
				}else{
					if(_.find(this.value, {lang})){
						_.find(this.value, {lang}).value = val
					}else{
						this.value.push({lang, value: val})
					}					
				}
				if(!this.okbtn){
					this.$emit('input', this.value)
				}
            }
		}
	},

	methods: {
		change(){
			this.$emit('input', this.value)
			this.$emit('on-change', this.lang, _.find(this.value, {lang: this.lang}).value)
		},
		isActive(lang){
			if(_.find(this.value, {lang})){
				return _.find(this.value, {lang}).value.length > 0
			}
			return false
		}
	}
}
</script>
<style>
	body .el-input__inner{
		height:43px;
	}

</style>