/** AUTH */
export const SESSION_REQUEST = 'SESSION_REQUEST';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FORGET = 'AUTH_FORGET';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CHANGEPASS = 'AUTH_CHANGEPASS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
/** USER */
export const USER_REQUEST = 'USER_REQUEST';
export const USER_TYPE = 'USER_TYPE';
export const USER_ADD = 'USER_ADD';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_ALL = 'USER_ALL';
export const USER_ALL_SUCCESS = 'USER_ALL_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
/** DOCTOR */
export const DOCTOR_REQUEST = 'DOCTOR_REQUEST';
export const DOCTOR_LIST = 'DOCTOR_LIST';
export const DOCTOR_ADD = 'DOCTOR_ADD';
export const DOCTOR_UPDATE = 'DOCTOR_UPDATE';
export const DOCTOR_DELETE = 'DOCTOR_DELETE';
export const DOCTOR_SUCCESS = 'DOCTOR_SUCCESS';
export const DOCTOR_ERROR = 'DOCTOR_ERROR';
export const DOCTOR_UPLOAD = 'DOCTOR_UPLOAD';
/** PATIENT */
export const PATIENT_REGISTER = 'PATIENT_REGISTER';
export const PATIENT_REQUEST = 'PATIENT_REQUEST';
export const PATIENT_LIST = 'PATIENT_LIST';
export const PATIENT_ADD = 'PATIENT_ADD';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_DELETE = 'PATIENT_DELETE';
export const PATIENT_SUCCESS = 'PATIENT_SUCCESS';
export const PATIENT_ERROR = 'PATIENT_ERROR';
export const PATIENT_UPLOAD = 'PATIENT_UPLOAD';
export const PATIENT_FILES = 'PATIENT_FILES';
export const PATIENT_FILE_DELETE = 'PATIENT_FILE_DELETE';
export const PATIENT_NOTE_REQUEST = 'PATIENT_NOTE_REQUEST';
export const PATIENT_NOTE_LIST = 'PATIENT_NOTE_LIST';
export const PATIENT_NOTE_ADD = 'PATIENT_NOTE_ADD';
export const PATIENT_NOTE_DELETE = 'PATIENT_NOTE_DELETE';
/** CLINIC */
export const CLINIC_REQUEST = 'CLINIC_REQUEST';
export const CLINIC_LIST = 'CLINIC_LIST';
export const CLINIC_ADD = 'CLINIC_ADD';
export const CLINIC_UPDATE = 'CLINIC_UPDATE';
export const CLINIC_DELETE = 'CLINIC_DELETE';
export const CLINIC_SUCCESS = 'CLINIC_SUCCESS';
export const CLINIC_ERROR = 'CLINIC_ERROR';
/** BRANCH */
export const BRANCH_REQUEST = 'BRANCH_REQUEST';
export const BRANCH_LIST_REQUEST = 'BRANCH_LIST_REQUEST';
export const BRANCH_LIST = 'BRANCH_LIST';
export const BRANCH_ADD = 'BRANCH_ADD';
export const BRANCH_UPDATE = 'BRANCH_UPDATE';
export const BRANCH_DELETE = 'BRANCH_DELETE';
export const BRANCH_SUCCESS = 'BRANCH_SUCCESS';
export const BRANCH_ERROR = 'BRANCH_ERROR';
/** Country & City */
export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const CITY_REQUEST = 'CITY_REQUEST';
export const CITY_SUCCESS = 'CITY_SUCCESS';
export const CTRY_ERROR = 'CTRY_ERROR';
/** SETTING */
export const SETTING_REQUEST = 'SETTING_REQUEST';
export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_LIST = 'SETTING_LIST';
export const SETTING_ERROR = 'SETTING_ERROR';
export const SETTING_SUCCESS = 'SETTING_SUCCESS';
/** Add Setting Const */
/** SOCKET */
export const SOCKET_NOTIFY = 'SOCKET_NOTIFY';
/** NOTIFY */
export const NOTIFY_LIST_REQUEST = 'NOTIFY_LIST_REQUEST';
export const NOTIFY_REQUEST = 'NOTIFY_REQUEST';
export const NOTIFY_READALL = 'NOTIFY_READALL';
export const NOTIFY_UPDATE = 'NOTIFY_UPDATE';
export const NOTIFY_LIST = 'NOTIFY_LIST';
export const NOTIFY_LIST_ALL = 'NOTIFY_LIST_ALL';
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
/** AVAILABILITY */
export const AVAILABILITY_REQUEST = 'AVAILABILITY_REQUEST';
export const AVAILABILITY_LIST = 'AVAILABILITY_LIST';
export const AVAILABILITY_ADD = 'AVAILABILITY_ADD';
export const AVAILABILITY_UPDATE = 'AVAILABILITY_UPDATE';
export const AVAILABILITY_SUCCESS = 'AVAILABILITY_SUCCESS';
export const AVAILABILITY_ERROR = 'AVAILABILITY_ERROR';
/** CALENDAR */
export const CALENDAR_REQUEST = 'CALENDAR_REQUEST';
export const CALENDAR_LIST = 'CALENDAR_LIST';
export const CALENDAR_ADD = 'CALENDAR_ADD';
export const CALENDAR_UPDATE = 'CALENDAR_UPDATE';
export const CALENDAR_SUCCESS = 'CALENDAR_SUCCESS';
export const CALENDAR_ERROR = 'CALENDAR_ERROR';
export const CALENDAR_SHARE = 'CALENDAR_SHARE';
/** ARTICLE */
export const ARTICLE_REQUEST = 'ARTICLE_REQUEST';
export const ARTICLE_LIST = 'ARTICLE_LIST';
export const ARTICLE_IMG_LIST = 'ARTICLE_IMG_LIST';
export const ARTICLE_ADD = 'ARTICLE_ADD';
export const ARTICLE_UPDATE = 'ARTICLE_UPDATE';
export const ARTICLE_UPLOAD = 'ARTICLE_UPLOAD';
export const ARTICLE_IMG_DELETE = 'ARTICLE_IMG_DELETE';
export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_ERROR = 'ARTICLE_ERROR';
/** PAGE */
export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_LIST = 'PAGE_LIST';
export const PAGE_ADD = 'PAGE_ADD';
export const PAGE_UPDATE = 'PAGE_UPDATE';
export const PAGE_DELETE = 'PAGE_DELETE';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';
/** BANK */
export const BANK_REQUEST = 'BANK_REQUEST';
export const BANK_LIST = 'BANK_LIST';
export const BANK_ADD = 'BANK_ADD';
export const BANK_UPDATE = 'BANK_UPDATE';
export const BANK_DELETE = 'BANK_DELETE';
export const BANK_SUCCESS = 'BANK_SUCCESS';
export const BANK_ERROR = 'BANK_ERROR';
/** RECIPE */
export const RECIPE_REQUEST = 'RECIPE_REQUEST';
export const RECIPE_LIST = 'RECIPE_LIST';
export const RECIPE_ADD = 'RECIPE_ADD';
export const RECIPE_UPDATE = 'RECIPE_UPDATE';
export const RECIPE_DELETE = 'RECIPE_DELETE';
export const RECIPE_SUCCESS = 'RECIPE_SUCCESS';
export const RECIPE_ERROR = 'RECIPE_ERROR';
/** REPORT */
export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_LIST = 'REPORT_LIST';
export const REPORT_ADD = 'REPORT_ADD';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_DELETE = 'REPORT_DELETE';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_ERROR = 'REPORT_ERROR';
/** APPOINTMENT */
export const APPOINTMENT_REQUEST = 'APPOINTMENT_REQUEST';
export const APPOINTMENT_LIST = 'APPOINTMENT_LIST';
export const APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE';
export const APPOINTMENT_SUCCESS = 'APPOINTMENT_SUCCESS';
export const APPOINTMENT_ERROR = 'APPOINTMENT_ERROR';
/** EMAIL */
export const EMAIL_REQUEST = 'EMAIL_REQUEST';
export const EMAIL_LIST = 'EMAIL_LIST';
export const EMAIL_UPDATE = 'EMAIL_UPDATE';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_ERROR = 'EMAIL_ERROR';
/** VIDEO */
export const VIDEO_REQUEST = 'VIDEO_REQUEST';
export const VIDEO_LIST = 'VIDEO_LIST';
export const VIDEO_ADD = 'VIDEO_ADD';
export const VIDEO_DELETE = 'VIDEO_DELETE';
export const VIDEO_SUCCESS = 'VIDEO_SUCCESS';
export const VIDEO_ERROR = 'VIDEO_ERROR';
/** PAYMENT */
export const PAYMENT_LIST = 'PAYMENT_LIST';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const PAYMENT_TRANSFER = 'PAYMENT_TRANSFER';
export const BALANCE_REQUEST = 'BALANCE_REQUEST';
/** COMMENT */
export const COMMENT_LIST = 'COMMENT_LIST';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';
export const COMMENT_SINGLE = 'COMMENT_SINGLE';
export const COMMENT_REQUEST = 'COMMENT_REQUEST';
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_ADD = 'COMMENT_ADD';
export const COMMENT_UPDATE = 'COMMENT_UPDATE';