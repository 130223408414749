<template>
	<base-input :label="label">
		<el-select v-model="selectedItem" :placeholder="$t('general.select')" clearable>
			<el-option v-for="(item, key) in records" :key="key" :label="`${translate ? getLangItem(item.name) : item.name}`" :value="item._id"></el-option>
		</el-select>
	</base-input>
</template>

<script>
import _ from 'lodash'
export default {
	name: 'DataSelector',
	props: {
		value: {
			type: String
		},
		translate: {
			type: Boolean,
			default: false
		},		
		mod: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			records: []
		}
	},
	computed: {
		selectedItem: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val ? val : null)
			}
		}
	},

	methods: {},
	mounted: function () {
		let vm = this
		vm.$nextTick(async () => {
			await this.$store.dispatch(`${this.mod}/list`)
			vm.records = this.$store.state[this.mod].records
		})
	}
}
</script>
<style>
body .el-input__inner {
	height: 43px;
}
</style>
