import Vue from 'vue'
import axios from '@/plugins/axios';
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		filter: {
			keyword: ''
		},
		page: 1,
		stats: {
			totalRecord: 0,
			currentPage: 1,
			pageCount: 0,
			pageSize: 10
		},
		records: [],
		record: {name: ''}
	},

	getters: {
		queryParams(state){
			return {
				page: state.page,
				status: state.filter.status,
				keyword: state.filter.keyword,
			}
		}
	},

	mutations: {
		setRecords(state, records){
			state.records = records
		},
		setStats(state, stats){
			state.stats = stats
		},
		setPage(state, page) {
			state.page = page
		},
		setRecord(state, data){
			state.record = data
		},
		setFilter(state, data){
			state.filter[data.key] = data.value
		},
		updateData(state, data){
			Vue.set(state.records, _.findIndex(state.records, {_id: data._id}), data)
		}
	},

	actions: {
		async list({commit, getters}){
			const response = (await axios.get(`/treatment`, {params: getters.queryParams})).data
			commit('setRecords', response.records)
			commit('setStats', response.stats)
			return response
		},

		async get({commit}, id){
			const record = (await axios.get(`/treatment/${id}`)).data.record
			commit('setRecord', record)
			return _.cloneDeep(record)
		},
		async create({state, commit, dispatch}, nameList){
			const result = (await axios.post(`/treatment`, {name: nameList})).data
			dispatch('list')
			return result
		},
		async update({state, commit}){
			const record = (await axios.put(`/treatment/${state.record._id}`, state.record)).data.record
			commit('updateData', record)
			return record
		},
		async delete({state, commit, dispatch}, _id){
			const record = (await axios.delete(`/treatment/${_id}`)).data.record
			dispatch('list')
			return record
		}
	}
}