import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    COMMENT_LIST,
    COMMENT_ERROR,
    COMMENT_REQUEST,
    COMMENT_LIST_REQUEST,
    COMMENT_SINGLE,
    COMMENT_SUCCESS,
    COMMENT_UPDATE,
    COMMENT_ADD
} from './define';

const state = {
    status: '',
    comments: [],
    total: 0,
    pages: 0
};

const getters = {
    getComments: state => state.comments,
    getCommentTotal: state => state.total,
    getCommentPages: state => state.pages
};

const actions = {
    [COMMENT_LIST_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(COMMENT_REQUEST);
            apiCall({ url: 'comment/list', data, method: 'POST' })
                .then(resp => {
                    commit(COMMENT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(COMMENT_ERROR);
                    reject(err);
                });
        });
    },
    [COMMENT_REQUEST]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(COMMENT_REQUEST);
            apiCall({ url: 'comment/'+uuid})
                .then(resp => {
                    commit(COMMENT_SINGLE, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(COMMENT_ERROR);
                    reject(err);
                });
        });
    },
    [COMMENT_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(COMMENT_REQUEST);
            apiCall({
                url: 'comment/' + data.uuid,
                data: data.comment,
                method: 'PUT'
            })
                .then(resp => {
                    commit(COMMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(COMMENT_ERROR);
                    reject(err);
                });
        });
    },
    [COMMENT_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(COMMENT_REQUEST);
            apiCall({
                url: 'comment/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(COMMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(COMMENT_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [COMMENT_REQUEST]: state => {
        state.status = 'loading';
    },
    [COMMENT_SUCCESS]: state => {
        state.status = 'success';
    },
    [COMMENT_ERROR]: state => {
        state.status = 'error';
    },
    [COMMENT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'comments', resp.comments);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    [COMMENT_SINGLE]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'comments', resp.comments);
        Vue.set(state, 'total', 0);
        Vue.set(state, 'pages', 0);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
