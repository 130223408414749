import BaseInput from '@/components/Arg/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/Arg/BaseDropdown.vue';
import Card from '@/components/Arg/Cards/Card.vue';
import Modal from '@/components/Arg/Modal.vue';
import StatsCard from '@/components/Arg/Cards/StatsCard.vue';
import BaseButton from '@/components/Arg/BaseButton.vue';
import Badge from '@/components/Arg/Badge.vue';
import BaseCheckbox from '@/components/Arg/Inputs/BaseCheckbox.vue';
import BaseRadio from '@/components/Arg/Inputs/BaseRadio';
import BaseProgress from '@/components/Arg/BaseProgress';
import BasePagination from '@/components/Arg/BasePagination';
import BaseAlert from '@/components/Arg/BaseAlert';
import BaseNav from '@/components/Arg/Navbar/BaseNav';
import BaseHeader from '@/components/Arg/BaseHeader';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Table, TableColumn, Select, Option, Input, InputNumber } from 'element-ui';

const GlobalComponents = {
    install(Vue) {
        Vue.component(Badge.name, Badge);
        Vue.component(BaseAlert.name, BaseAlert);
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseHeader.name, BaseHeader);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseNav.name, BaseNav);
        Vue.component(BasePagination.name, BasePagination);
        Vue.component(BaseProgress.name, BaseProgress);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(Card.name, Card);
        Vue.component(Modal.name, Modal);
        Vue.component(StatsCard.name, StatsCard);
        Vue.component(Table.name, Table);
        Vue.component(TableColumn.name, TableColumn);
        Vue.component(Select.name, Select);
        Vue.component(Option.name, Option);
        Vue.component(Input.name, Input);
        Vue.component(InputNumber.name, InputNumber);
        Vue.component('validation-provider', ValidationProvider);
        Vue.component('validation-observer', ValidationObserver);
    }
};

export default GlobalComponents;
