<template>
	<div>
		<div>
			<b-tabs content-class="mt-1" v-model="tabIndex">
				<b-tab :title="lang.toUpperCase()" v-for="(lang, index) in activeLanguages" :key="index"></b-tab>
			</b-tabs>
		</div>
		<b-form-textarea
			:class="errors[0] ? 'is-invalid' : ''"
			:placeholder="placeholder+': '+lang"
			rows="5"
			no-resize
			v-model="languageValue"
		></b-form-textarea>

		<div v-if="okbtn" style="margin-top: 5px">
			<base-button type="primary" size="sm" @click="change">{{ $t('general.btnUpdate') }}</base-button>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
export default {
	name: 'LanguageTextarea',
	props: {
		value: {
			type: Array
		},
		placeholder: {
			type: String,
			default: ''
		},
		errors: {
			type: Array
		},
		okbtn: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeLanguages: ['tr', 'en', 'de', 'ru'],
			lang: 'tr',
			tab: 0
		}
	},
	computed: {
		tabIndex: {
			get(){
				return this.tab
			},
			set(val){
				this.tab = val
				this.lang = this.activeLanguages[val]
			}

		},
		langItem: {
			get() {
				return this.lang
			},
			set(val) {
				this.lang = val.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/ +(?= )/g, '')
			}
		},
		compLanguages() {
			return _.sortBy(this.activeLanguages, lang => ((_.find(this.value, {lang}) ? _.find(this.value, {lang}).value : '') == '' ? 1 : 0))
		},
		languageValue: {
			get() {
				var lang = this.lang
				if (_.find(this.value, {lang})) {
					return _.find(this.value, {lang}).value
				}
				return ''
			},
			set(val) {
				var lang = this.lang
				if (val.length == 0) {
					if (_.find(this.value, {lang})) {
						this.value.splice(_.findIndex(this.value, {lang}), 1)
					}
				} else {
					if (_.find(this.value, {lang})) {
						_.find(this.value, {lang}).value = val
					} else {
						this.value.push({lang, value: val})
					}
				}
				if (!this.okbtn) {
					this.$emit('input', this.value)
				}
			}
		}
	},

	methods: {
		change() {
			this.$emit('input', this.value)
			this.$emit('on-change', this.lang, _.find(this.value, {lang: this.lang}).value)
		},
		isActive(lang) {
			if (_.find(this.value, {lang})) {
				return _.find(this.value, {lang}).value.length > 0
			}
			return false
		}
	}
}
</script>
<style>
body .el-input__inner {
	height: 43px;
}
</style>
