import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    BANK_REQUEST,
    BANK_LIST,
    BANK_ADD,
    BANK_UPDATE,
    BANK_DELETE,
    BANK_SUCCESS,
    BANK_ERROR
} from './define';

const state = {
    status: '',
    banks: []
};

const getters = {
    getBanks: state => state.banks
};

const actions = {
    [BANK_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(BANK_REQUEST);
            apiCall({ url: 'bank/list', data, method: 'POST' })
                .then(resp => {
                    commit(BANK_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(BANK_ERROR);
                    reject(err);
                });
        });
    },
    [BANK_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(BANK_REQUEST);
            apiCall({
                url: 'bank/' + data.id,
                data: data.bank,
                method: 'PUT'
            })
                .then(resp => {
                    commit(BANK_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BANK_ERROR);
                    reject(err);
                });
        });
    },
    [BANK_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(BANK_REQUEST);
            apiCall({
                url: 'bank',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(BANK_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BANK_ERROR);
                    reject(err);
                });
        });
    },
    [BANK_DELETE]: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            commit(BANK_REQUEST);
            apiCall({
                url: 'bank/' + id,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(BANK_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(BANK_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [BANK_REQUEST]: state => {
        state.status = 'loading';
    },
    [BANK_SUCCESS]: state => {
        state.status = 'success';
    },
    [BANK_ERROR]: state => {
        state.status = 'error';
    },
    [BANK_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'banks', resp.banks);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
