import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    NOTIFY_LIST_ALL,
    NOTIFY_LIST,
    NOTIFY_REQUEST,
    NOTIFY_ERROR,
    NOTIFY_LIST_REQUEST,
    NOTIFY_READALL,
    NOTIFY_SUCCESS
} from './define';

const state = {
    status: '',
    notifies: [],
    notifyList: [],
    total: 0,
    pages: 0,
    unread: 0
};

const getters = {
    getNotifies: state => state.notifies,
    getNotifyList: state => state.notifyList,
    getNotifyTotal: state => state.total,
    getNotifyPages: state => state.pages,
    getNotifyUnread: state => state.unread
};

const actions = {
    [NOTIFY_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFY_REQUEST);
            apiCall({ url: 'notify/list', data, method: 'POST' })
                .then(resp => {
                    commit(NOTIFY_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(NOTIFY_ERROR);
                    reject(err);
                });
        });
    },
    [NOTIFY_LIST_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFY_REQUEST);
            apiCall({ url: 'notify/list', data, method: 'POST' })
                .then(resp => {
                    commit(NOTIFY_LIST_ALL, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(NOTIFY_ERROR);
                    reject(err);
                });
        });
    },
    [NOTIFY_READALL]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'notify/readall', data, method: 'PUT' })
                .then(resp => {
                    commit(NOTIFY_ERROR);
                    dispatch(NOTIFY_REQUEST);
                    resolve(true);
                })
                .catch(err => {
                    commit(NOTIFY_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [NOTIFY_REQUEST]: state => {
        state.status = 'loading';
    },
    [NOTIFY_SUCCESS]: state => {
        state.status = 'success';
    },
    [NOTIFY_ERROR]: state => {
        state.status = 'error';
    },
    [NOTIFY_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'notifies', resp.notifies);
        Vue.set(state, 'unread', resp.unread);
    },
    [NOTIFY_LIST_ALL]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'notifyList', resp.notifies);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
        Vue.set(state, 'unread', resp.unread);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
