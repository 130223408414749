import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    CLINIC_ADD,
    CLINIC_DELETE,
    CLINIC_LIST,
    CLINIC_ERROR,
    CLINIC_REQUEST,
    CLINIC_SUCCESS,
    CLINIC_UPDATE
} from './define';

const state = {
    status: '',
    clinics: [],
    clinic: {},
    total: 0,
    pages: 0
};

const getters = {
    getClinic: state => state.clinic,
    getClinics: state => state.clinics,
    getClinicTotal: state => state.total,
    getClinicPages: state => state.pages
};

const actions = {
    [CLINIC_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(CLINIC_REQUEST);
            apiCall({ url: 'clinic/list', data, method: 'POST' })
                .then(resp => {
                    commit(CLINIC_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(CLINIC_ERROR);
                    reject(err);
                });
        });
    },
    ['CLINIC_EXISTS']: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'clinic/web', data, method: 'POST' })
                .then(resp => {
                    if(resp.status && resp.clinic){
                        commit('SET_CLINIC', resp.clinic);
                        resolve(resp.clinic);
                    }
                    resolve(false);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [CLINIC_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(CLINIC_REQUEST);
            apiCall({
                url: 'clinic/' + data.uuid,
                data: data.clinic,
                method: 'PUT'
            })
                .then(resp => {
                    commit(CLINIC_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(CLINIC_ERROR);
                    reject(err);
                });
        });
    },
    [CLINIC_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(CLINIC_REQUEST);
            apiCall({
                url: 'clinic/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(CLINIC_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(CLINIC_ERROR);
                    reject(err);
                });
        });
    },
    [CLINIC_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(CLINIC_REQUEST);
            apiCall({
                url: 'clinic/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(CLINIC_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(CLINIC_ERROR);
                    reject(err);
                });
        });
    },
    ['CLINIC_UPLOAD']: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('file', data.file);

            apiCall(
                {
                    url: 'file/clinic/image',
                    data: formData,
                    method: 'POST'
                },
                { clinic: data.clinic, 'Content-Type': 'multipart/form-data' }
            )
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    ['SET_CLINIC']: (state, resp) => {
        state.clinic = resp;
    },
    [CLINIC_REQUEST]: state => {
        state.status = 'loading';
    },
    [CLINIC_SUCCESS]: state => {
        state.status = 'success';
    },
    [CLINIC_ERROR]: state => {
        state.status = 'error';
    },
    [CLINIC_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'clinics', resp.clinics);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
