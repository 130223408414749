import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import _ from 'lodash';
import {
    SETTING_LIST,
    SETTING_ERROR,
    SETTING_REQUEST,
    SETTING_SUCCESS,
    SETTING_UPDATE
} from './define';

const state = {
    setting: {},
    mail: {},
    sms: {}
};

const getters = {
    generalSetting: state => state.setting,
    mailSetting: state => state.mail,
    smsSetting: state => state.sms
};

const actions = {
    [SETTING_REQUEST]: ({ commit, rootState }) => {
        return new Promise((resolve, reject) => {
            commit(SETTING_REQUEST);
            let idList =
                rootState.user.type == 'admin'
                    ? ['sms', 'setting', 'mail']
                    : 'setting';
            apiCall({ url: 'setting/list', data: { idList }, method: 'POST' })
                .then(resp => {
                    commit(SETTING_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(SETTING_ERROR);
                    reject(err);
                });
        });
    },
    [SETTING_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(SETTING_REQUEST);
            apiCall({
                url: 'setting/' + data.id,
                data: { data: data.setting },
                method: 'PUT'
            })
                .then(resp => {
                    commit(SETTING_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(SETTING_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [SETTING_REQUEST]: state => {
        state.status = 'loading';
    },
    [SETTING_SUCCESS]: state => {
        state.status = 'success';
    },
    [SETTING_ERROR]: state => {
        state.status = 'error';
    },
    [SETTING_LIST]: (state, resp) => {
        state.status = 'success';
        _.forEach(resp.settings, item => {
            Vue.set(state, item.id, item.data);
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
