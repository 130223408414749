import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    VIDEO_ADD,
    VIDEO_DELETE,
    VIDEO_LIST,
    VIDEO_ERROR,
    VIDEO_REQUEST,
    VIDEO_SUCCESS
} from './define';

const state = {
    status: '',
    videos: []
};

const getters = {
    getVideos: state => state.videos
};

const actions = {
    [VIDEO_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(VIDEO_REQUEST);
            apiCall({ url: 'doctor/video/list' })
                .then(resp => {
                    commit(VIDEO_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(VIDEO_ERROR);
                    reject(err);
                });
        });
    },
    [VIDEO_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(VIDEO_REQUEST);
            apiCall({
                url: 'doctor/addvideo',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(VIDEO_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(VIDEO_ERROR);
                    reject(err);
                });
        });
    },
    [VIDEO_DELETE]: ({ commit }, index) => {
        return new Promise((resolve, reject) => {
            commit(VIDEO_REQUEST);
            apiCall({
                url: 'doctor/deletevideo/' + index,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(VIDEO_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(VIDEO_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [VIDEO_REQUEST]: state => {
        state.status = 'loading';
    },
    [VIDEO_SUCCESS]: state => {
        state.status = 'success';
    },
    [VIDEO_ERROR]: state => {
        state.status = 'error';
    },
    [VIDEO_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'videos', resp.videos);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
