<template>
	<div>
		<b-tabs content-class="mt-1" v-model="tabIndex">
			<b-tab :title="lang.toUpperCase()" v-for="(lang, index) in activeLanguages" :key="index"></b-tab>
		</b-tabs>
		<quill-editor v-model="languageValue" :options="editorOption" />
	</div>
</template>

<script>
import _ from 'lodash'
export default {
	name: 'HtmlEditor',
	props: {
		value: {
			type: Array
		},
		placeholder: {
			type: String,
			default: ''
		},
		module: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			activeLanguages: ['tr', 'en', 'de', 'ru'],
			lang: 'tr',
			tab: 0,
			editorOption: {
				modules: {
					clipboard: {
						matchVisual: true
					},
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						['blockquote'],
						[{list: 'ordered'}, {list: 'bullet'}],
						[{indent: '-1'}, {indent: '+1'}],
						[{size: ['small', false, 'large', 'huge']}],
						[{header: [1, 2, 3, 4, 5, 6, false]}],
						[{color: []}, {background: []}],
						[{font: []}],
						[{align: []}],
						['clean'],
						['link', 'image']
					]
				},
				placeholder: ''
			}
		}
	},
	computed: {
		tabIndex: {
			get(){
				return this.tab
			},
			set(val){
				this.tab = val
				this.lang = this.activeLanguages[val]
			}

		},

		languageValue: {
			get() {
				var lang = this.lang
				if (_.find(this.value, {lang})) {
					return _.find(this.value, {lang}).value
				}
				return ''
			},
			set(val) {
				var lang = this.lang
				if (val.length == 0) {
					if (_.find(this.value, {lang})) {
						this.value.splice(_.findIndex(this.value, {lang}), 1)
					}
				} else {
					if (_.find(this.value, {lang})) {
						_.find(this.value, {lang}).value = val
					} else {
						this.value.push({lang, value: val})
					}
				}
			}
		}
	},

	methods: {},
	async created() {}
}
</script>
