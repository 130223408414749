import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    EMAIL_REQUEST,
    EMAIL_LIST,
    EMAIL_UPDATE,
    EMAIL_SUCCESS,
    EMAIL_ERROR
} from './define';

const state = {
    status: '',
    emails: [],
};

const getters = {
    getEmails: state => state.emails,
};

const actions = {
    [EMAIL_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(EMAIL_REQUEST);
            apiCall({ url: 'email/schemalist'})
                .then(resp => {
                    commit(EMAIL_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(EMAIL_ERROR);
                    reject(err);
                });
        });
    },
    [EMAIL_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(EMAIL_REQUEST);
            apiCall({
                url: 'email/schema/' + data.id,
                data: data.email,
                method: 'PUT'
            })
                .then(resp => {
                    commit(EMAIL_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(EMAIL_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [EMAIL_REQUEST]: state => {
        state.status = 'loading';
    },
    [EMAIL_SUCCESS]: state => {
        state.status = 'success';
    },
    [EMAIL_ERROR]: state => {
        state.status = 'error';
    },
    [EMAIL_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'emails', resp.emails);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
