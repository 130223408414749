import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    REPORT_ADD,
    REPORT_DELETE,
    REPORT_LIST,
    REPORT_ERROR,
    REPORT_REQUEST,
    REPORT_SUCCESS,
    REPORT_UPDATE
} from './define';

const state = {
    status: '',
    reports: [],
    total: 0,
    pages: 0
};

const getters = {
    getReports: state => state.reports,
    getReportTotal: state => state.total,
    getReportPages: state => state.pages
};

const actions = {
    [REPORT_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(REPORT_REQUEST);
            apiCall({ url: 'report/list', data, method: 'POST' })
                .then(resp => {
                    commit(REPORT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(REPORT_ERROR);
                    reject(err);
                });
        });
    },
    [REPORT_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(REPORT_REQUEST);
            apiCall({
                url: 'report/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(REPORT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(REPORT_ERROR);
                    reject(err);
                });
        });
    },
    [REPORT_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(REPORT_REQUEST);
            apiCall({
                url: 'report/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(REPORT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(REPORT_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [REPORT_REQUEST]: state => {
        state.status = 'loading';
    },
    [REPORT_SUCCESS]: state => {
        state.status = 'success';
    },
    [REPORT_ERROR]: state => {
        state.status = 'error';
    },
    [REPORT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'reports', resp.reports);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
