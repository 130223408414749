<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { SESSION_REQUEST } from '@/store/modules/define'
export default {
    created(){
        if(!this.$store.getters.sessionExists){
            this.$store.dispatch(SESSION_REQUEST);
        }
    }
}
</script>
