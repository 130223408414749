import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Plugins from './plugins';
import filters from './utils/filters';

import { i18n } from './plugins/i18n';

import LanguageInput from '@/tools/LanguageInput.vue'
import LanguageTextarea from '@/tools/LanguageTextarea.vue'
import DataSelector from '@/components/DataSelector.vue'
import HtmlEditor from '@/tools/HtmlEditor.vue'
import {mapGetters} from 'vuex'

import {getCookie} from '@/utils/cookie'

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '380422872354-bh0ssst1tvetlg8gao5r6ebfhqfi2pda.apps.googleusercontent.com',
  scope: 'openid profile email https://www.googleapis.com/auth/calendar',
  prompt: 'consent',
  fetch_basic_profile: false
}
Vue.use(GAuth, gauthOption)

Vue.use(Plugins);

Vue.mixin({ 
    components: {
        LanguageInput,
        DataSelector,
        LanguageTextarea,
        HtmlEditor
    },
    data: function () {
        return {
            //config
        }
    },
    computed: {
        ...mapGetters(['getLoading'])
    },
    methods: {
        getLangItem(obj) {
            var item = _.find(obj, {lang: this.$i18n.locale})
            return item ? item.value : _.find(obj, {lang: 'en'}) ? _.find(obj, {lang: 'en'}).value : ''
        },
        getDate: (value, format = 'DD.MM.YYYY HH:mm') => {
            const lang = getCookie('user-lang')
            return moment(value).locale(lang).format(format)
        },
        getHtmlText: text => {
            return text && text.trim() != '' ? text.replace(/(?:\r\n|\r|\n)/g, '<br>') : ''
        }
    },
    filters
});

Vue.$cookies.config('7d', '/', '', false, 'Strict');

Vue.config.productionTip = false;

new Vue({
    data() {
        return {
            defaultMapOptions: {
                gestureHandling: 'greedy',
                clickableIcons: false,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoom: 18,
                minZoom: 3,
                center: {
                    lat: 36.89659,
                    lng: 30.71076
                }
            }
        };
    },
    sockets: {
        connect: function() {
            console.log('socket connected');
        },
        customEmit: function(data) {
            console.log(
                'this method was fired by the socket server. eg: io.emit("customEmit", data)'
            );
        }
    },
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
