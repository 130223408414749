import apiCall from '@/utils/apiCall';
import { getCookie, setCookie, removeCookie } from '@/utils/cookie';
import {
    SESSION_REQUEST,
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_FORGET,
    AUTH_CHANGEPASS,
    AUTH_LOGOUT,
    USER_REQUEST
} from './define';

const state = {
    session: getCookie('user-session') || '',
    token: getCookie('user-token') || '',
    clinic: getCookie('user-clinic') || null,
    status: '',
    hasLoadedOnce: false
};

const getters = {
    sessionExists: state => !!state.session,
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTH_CHANGEPASS]: ({ rootState }, data) => {
        return new Promise((resolve, reject) => {
            apiCall(
                { url: 'auth/password/change', data, method: 'POST' },
                { lang: rootState.userLang }
            )
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [AUTH_FORGET]: ({ rootState }, data) => {
        return new Promise((resolve, reject) => {
            apiCall(
                { url: 'auth/password/forget', data, method: 'POST' },
                { lang: rootState.userLang }
            )
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SESSION_REQUEST]: () => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'session' })
                .then(resp => {
                    setCookie('user-session', resp.session);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [AUTH_REQUEST]: ({ commit, dispatch, state }, data) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            data['session'] = getCookie('user-session');
            let clinic = getCookie('user-clinic');
            if(clinic){
                data['clinic'] = clinic;
            }
            apiCall({ url: 'auth/' + data.type, data, method: 'POST' })
                .then(resp => {
                    setCookie('user-token', resp.token);
                    if (resp.session) {
                        setCookie('user-session', resp.session);
                    }
                    commit(AUTH_SUCCESS, resp);
                    //dispatch(USER_REQUEST);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    //console.log('auth_err', err)
                    //removeCookie('user-token');
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            removeCookie('user-token');
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.token = resp.token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = 'error';
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.token = '';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
