import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import _ from 'lodash';
import {
    PATIENT_ADD,
    PATIENT_DELETE,
    PATIENT_LIST,
    PATIENT_ERROR,
    PATIENT_REQUEST,
    PATIENT_SUCCESS,
    PATIENT_UPDATE,
    PATIENT_UPLOAD,
    PATIENT_NOTE_REQUEST,
    PATIENT_NOTE_ADD,
    PATIENT_NOTE_DELETE,
    PATIENT_NOTE_LIST,
    PATIENT_REGISTER,
    PATIENT_FILES,
    PATIENT_FILE_DELETE
} from './define';

const state = {
    status: '',
    patients: [],
    files: [],
    notes: [],
    total: 0,
    pages: 0
};

const getters = {
    getPatients: state => state.patients,
    getNotes: state => state.notes,
    getFiles: state => state.files,
    getPatientTotal: state => state.total,
    getPatientPages: state => state.pages
};

const actions = {
    [PATIENT_NOTE_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'patient/getnote',
                data: { patient: data.uuid },
                method: 'POST'
            })
                .then(resp => {
                    commit(PATIENT_NOTE_LIST, { resp, index: data.index });
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [PATIENT_NOTE_ADD]: ({ commit }, formData = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'patient/addnote',
                data: formData,
                method: 'POST'
            })
                .then(resp => {
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [PATIENT_REGISTER]: ({}, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'patient/add', data, method: 'POST' })
                .then(resp => {
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [PATIENT_FILES]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'patient/files',
                data,
                method: 'POST'
            })
                .then(resp => {
                    commit(PATIENT_FILES, resp);
                    resolve(true);
                })
                .catch(err => {
                    //console.log(err);
                    reject(err);
                });
        });
    },
    [PATIENT_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(PATIENT_REQUEST);
            apiCall({ url: 'patient/list', data, method: 'POST' })
                .then(resp => {
                    commit(PATIENT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(PATIENT_ERROR);
                    reject(err);
                });
        });
    },
    [PATIENT_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PATIENT_REQUEST);
            apiCall({
                url: 'patient/' + data.uuid,
                data: data.patient,
                method: 'PUT'
            })
                .then(resp => {
                    commit(PATIENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PATIENT_ERROR);
                    reject(err);
                });
        });
    },
    [PATIENT_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PATIENT_REQUEST);
            apiCall({
                url: 'patient/add',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(PATIENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PATIENT_ERROR);
                    reject(err);
                });
        });
    },
    [PATIENT_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(PATIENT_REQUEST);
            apiCall({
                url: 'patient/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(PATIENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PATIENT_ERROR);
                    reject(err);
                });
        });
    },
    [PATIENT_NOTE_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'patient/deletenote/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [PATIENT_FILE_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            apiCall({
                url: 'patient/file/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [PATIENT_UPLOAD]: ({ commit }, data) => {
        return new Promise(async (resolve, reject) => {
            let formData = new FormData();

            await Promise.all(
                _.forEach(data.file, file => {
                    formData.append('document', file);
                })
            );

            apiCall(
                {
                    url: 'patient/upload',
                    data: formData,
                    method: 'POST'
                },
                { user: data.user, 'Content-Type': 'multipart/form-data' }
            )
                .then(resp => {
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    [PATIENT_REQUEST]: state => {
        state.status = 'loading';
    },
    [PATIENT_SUCCESS]: state => {
        state.status = 'success';
    },
    [PATIENT_ERROR]: state => {
        state.status = 'error';
    },
    [PATIENT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'patients', resp.patients);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    [PATIENT_NOTE_LIST]: (state, data) => {
        if (state.patients[data.index] != undefined) {
            Vue.set(state.patients[data.index], 'notes', data.resp.notes);
        } else {
            Vue.set(state, 'notes', data.resp.notes);
        }
    },
    [PATIENT_FILES]: (state, resp) => {
        Vue.set(state, 'files', resp.files);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
