import Vue from 'vue';
import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    CALENDAR_REQUEST,
    CALENDAR_LIST,
    CALENDAR_ADD,
    CALENDAR_UPDATE,
    CALENDAR_SUCCESS,
    CALENDAR_ERROR,
    CALENDAR_SHARE
} from './define';

const state = {
    status: '',
    calendar: [],
    shareUrl: ''
};

const getters = {
    getCalendar: state => state.calendar,
    getShareUrl: state => state.shareUrl,
};

const actions = {
    [CALENDAR_ADD]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(CALENDAR_REQUEST);
            if (data.type == 'file') {
                let formData = new FormData();
                formData.append('file', data.file);

                commit(CALENDAR_REQUEST);
                apiCall(
                    {
                        url: 'calendar/ical/' + data.type,
                        data: formData,
                        method: 'POST'
                    },
                    { 'Content-Type': 'multipart/form-data' }
                )
                    .then(resp => {
                        commit(CALENDAR_SUCCESS);
                        resolve(true);
                    })
                    .catch(err => {
                        commit(CALENDAR_ERROR);
                        reject(err);
                    });
            }else if(data.type=='token'){
                apiCall({
                    url: 'calendar/ical/' + data.type,
                    data,
                    method: 'POST'
                })
                    .then(resp => {
                        commit(CALENDAR_SUCCESS);
                        resolve(true);
                    })
                    .catch(err => {
                        commit(CALENDAR_ERROR);
                        reject(err);
                    });
            }else {
                apiCall({
                    url: 'calendar/ical/' + data.type,
                    data,
                    method: 'POST'
                })
                    .then(resp => {
                        commit(CALENDAR_SUCCESS);
                        resolve(true);
                    })
                    .catch(err => {
                        commit(CALENDAR_ERROR);
                        reject(err);
                    });
            }
        });
    },
    [CALENDAR_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(CALENDAR_REQUEST);
            apiCall({ url: 'calendar/ical/' + data.start + '/' + data.end })
                .then(resp => {
                    commit(CALENDAR_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(CALENDAR_ERROR);
                    reject(err);
                });
        });
    },
    [CALENDAR_UPDATE]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(CALENDAR_REQUEST);
            apiCall({ url: 'calendar', data, method: 'PUT' })
                .then(resp => {
                    commit(CALENDAR_SUCCESS);
                    resolve(true);
                })
                .catch(err => {
                    commit(CALENDAR_ERROR);
                    reject(err);
                });
        });
    },
    [CALENDAR_SHARE]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'calendar/share', data, method: 'POST' })
                .then(resp => {
                    commit(CALENDAR_SHARE, resp);
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    [CALENDAR_REQUEST]: state => {
        state.status = 'loading';
    },
    [CALENDAR_SUCCESS]: state => {
        state.status = 'success';
    },
    [CALENDAR_ERROR]: state => {
        state.status = 'error';
    },
    [CALENDAR_SHARE]: (state, data) => {
        state.shareUrl = data.url;
    },
    [CALENDAR_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'calendar', resp.calendar);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
