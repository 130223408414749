import {
    SOCKET_NOTIFY,
    NOTIFY_REQUEST
} from './define';

const actions = {

}

export default {
    actions
}