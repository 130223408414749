import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getCookie, setCookie } from '@/utils/cookie';

let userLang = getCookie('user-lang');
if (!userLang || ['tr', 'en', 'de', 'ru'].indexOf(userLang) === -1) {
    userLang = 'tr';
    setCookie('user-lang', userLang);
}
//import axios from 'axios'

import trLangMessage from '../locales/tr';
import enLangMessage from '../locales/en';
import deLangMessage from '../locales/de';
import ruLangMessage from '../locales/ru';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: userLang,
    fallbackLocale: userLang,
    messages: {
        tr: trLangMessage,
        en: enLangMessage,
        de: deLangMessage,
        ru: ruLangMessage
    }
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    return import(
        /* webpackChunkName: "[request]" */ `@/locales/${lang}.json`
    ).then(messages => {
        i18n.setLocaleMessage(lang, messages.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
}
